import * as React from "react"
import Header from "../components/header/header";
import Footer from "components/footer/footer.jsx";
import BackToTop from "components/back-to-top/back-to-top.jsx";
import Seo from "components/seo/seo.jsx";
import Contacts from "components/contacts/contacts.jsx";


const ContactsPage = () => {



    return (
        <React.Fragment>
            <Seo />
            <Header />
            <Contacts />
            <Footer />
            <BackToTop />
        </React.Fragment>

    )
}

export default ContactsPage
