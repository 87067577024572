import React, {useEffect, useState} from 'react';
import * as styles from './contacts.module.scss';
import classNames from "classnames";
import {Accordion, Image, Table} from "react-bootstrap";





function Contacts() {
    return (
        <div className={styles.contacts}>
            <div className={styles.contacts__container}>
                <div className={classNames([`${styles.contacts__row}`], [`${styles.contacts__rowTitle}`])}>
                    <div className={styles.contacts__title}>
                        <h1>Контакты</h1>
                    </div>
                </div>
                <div className={styles.contacts__row}>
                    <div className={styles.contacts__cell}>
                        <Table striped responsive borderless={true}>
                            <tbody>
                            <tr>
                                <td>название</td>
                                <td>ООО «Частная практика доктора Котова»</td>
                            </tr>
                            <tr>
                                <td>юридический адрес:</td>
                                <td>Пермский край, г. Чернушка, бульвар Генерала Куприянова, д1</td>
                            </tr>
                            <tr>
                                <td>фактический адрес:</td>
                                <td>Пермский край, г. Чернушка, бульвар Генерала Куприянова, д1</td>
                            </tr>
                            <tr>
                                <td>сведения о постановке на учёт</td>
                                <td>ОГРН №1125957000180 поставлена на учёт 12 апреля 2012 года Межрайонной инспекцией Федеральной налоговой службы №13 по Пермскому краю. ИНН/КПК 5957016127/595701001</td>
                            </tr>
                            <tr>
                                <td>сведения о лицензии</td>
                                <td>Лицензия №ЛО-59-01-001658 от 4 сентября 2012 года бессрочно на осуществление медицинской деятельности.
                                    Виды услуг: стоматология, стоматология терапевтическая, стоматология ортопедическая, стоматология (доврачебная медицинская помощь), сестринское дело.
                                    Лицензия выдана Министерством здравоохранения Пермского края
                                    Г. Пермь, бульвар Гагарина, д 10
                                    Телефон отдела лицензирования
                                    8(342)265-55-43
                                </td>
                            </tr>
                            <tr>
                                <td>директор</td>
                                <td>Котов Евгений Юрьевич</td>
                            </tr>
                            <tr>
                                <td>телефоны</td>
                                <td>
                                    8(34-261)3-02-06 <br />
                                    8-908-25-34-798
                                </td>
                            </tr>
                            <tr>
                                <td>E-mail</td>
                                <td>info@стоматкотов.рф</td>
                            </tr>
                            </tbody>
                        </Table>


                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Свидетельство о государственной регистрации юридического лица</Accordion.Header>
                                <Accordion.Body>
                                    <Image src="/doc/doc_1.jpg" rounded />
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Лицензия на осуществление медицинской деятельности</Accordion.Header>
                                <Accordion.Body>
                                    <Image src="/doc/doc_3.jpg" rounded />
                                    <Image src="/doc/doc_4.jpg" rounded />
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header>О программе государственных гарантий бесплатного оказания медицинских услуг</Accordion.Header>
                                <Accordion.Body>
                                    <Image src="/doc/doc_2.jpg" rounded />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
                <div className={classNames([`${styles.contacts__row}`], [`${styles.contacts__rowTitle}`])}>
                    <div className={styles.contacts__title}>
                        <h1>
                            Адреса и телефоны органа исполнительной  власти
                        </h1>
                    </div>
                </div>
                <div className={styles.contacts__row}>
                    <div className={styles.contacts__cell}>
                        <Table striped responsive borderless={true}>
                            <thead>
                            <tr>
                                <th colSpan={2}>
                                    <b>Зам. Главы администрации Чернушинского городского округа по социальным вопросам</b>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Контактное лицо</td>
                                <td> Омелина Елена Егоровна </td>
                            </tr>
                            <tr>
                                <td>Адрес</td>
                                <td>617830, Пермский край, г. Чернушка, ул. Юбилейная, д. 9</td>
                            </tr>

                            <tr>
                                <td>Телефон</td>
                                <td>8(34-261)4-41-35</td>
                            </tr>
                            </tbody>


                            <thead>
                            <tr>
                                <th colSpan={2}>
                                    <b>Управление здравоохранения </b>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Контактное лицо</td>
                                <td>Старцев Дмитрий Геннадьевич </td>
                            </tr>
                            <tr>
                                <td>Адрес</td>
                                <td>617830, Пермский край, г. Чернушка, ул. Коммунистическая, д. 16</td>
                            </tr>

                            <tr>
                                <td>Телефон</td>
                                <td>
                                    8(34-261)4-42-99 <br />
                                    8(34-261)4-86-33
                                </td>
                            </tr>
                            </tbody>





                            <thead>
                            <tr>
                                <th colSpan={2}>
                                    <b>Министерство здравоохранения Пермского края</b>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Адрес</td>
                                <td>617830, Пермский край, г. Пермь, ул. Ленина, д. 51</td>
                            </tr>

                            <tr>
                                <td>Телефон</td>
                                <td>8(342)217-79-00</td>
                            </tr>
                            </tbody>



                            <thead>
                            <tr>
                                <th colSpan={2}>
                                    <b>Управление Росздравнадзора по Пермскому краю</b>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Адрес</td>
                                <td>617830, Пермский край, г. Пермь, ул. Петровская, д. 111</td>
                            </tr>

                            <tr>
                                <td>Телефон</td>
                                <td>
                                    8(342)237-01-57 <br />
                                    8(342)246-61-12
                                </td>
                            </tr>
                            </tbody>


                            <thead>
                            <tr>
                                <th colSpan={2}>
                                    <b>Южный территориальный отдел Федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека</b>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Адрес</td>
                                <td>617830, Пермский край, г. Чернушка, ул. Тельмана, д. 59  </td>
                            </tr>

                            <tr>
                                <td>Телефон</td>
                                <td>8(34-261)4-27-10</td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contacts;